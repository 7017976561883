import React, { FC } from 'react'
import { useScreenClass } from 'react-grid-system'
import styled from 'styled-components'
import { ImageBlock } from '../shared'

interface Prop {
  shows: {
    title: string
    shows: {
      title: string
      image: string
      link: string
    }[]
  }[]
}

const IGrid = styled.div<{ isDesktop: boolean }>`
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 1.75rem;
  row-gap: 4rem;
  margin: ${(props) => (props.isDesktop ? '4rem' : undefined)};
  margin-bottom: 12rem;
`

const Section = styled.section<{ isDesktop: boolean }>`
  text-align: center;
  .section-title {
    font-size: ${(props) => (props.isDesktop ? '50px' : '36px')};
    margin-bottom: ${(props) => (props.isDesktop ? '4rem' : '1rem')};
  }
`

const Grid: FC<Prop> = ({ shows }) => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)
  const shows2020 = shows[0].shows
  const shows2019 = shows[1].shows
  const shows2018 = shows[2].shows
  const allShows = [...shows2020, ...shows2019, ...shows2018]

  const sections = (
    <IGrid isDesktop={isDesktop}>
      {allShows.map((show) => (
        <ImageBlock
          title={show.title}
          src={show.image}
          color="black"
          alt={`${show.title} Podcast Cover`}
          link={show.link}
          imgStyles={{ width: '345px' }}
        />
      ))}
    </IGrid>
  )

  return <Section isDesktop={isDesktop}>{sections}</Section>
}

export default Grid
