import React, { FC } from 'react'
import { Col, Container, Row, useScreenClass } from 'react-grid-system'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

interface Prop {
  data: {
    title: string
    faculty: Fellows.Faculty[]
  }
}

const Section = styled.section<{ isDesktop: boolean }>`
  text-align: center;
  .section-title {
    font-size: ${(props) => (props.isDesktop ? '40px' : '32px')};
    margin-bottom: ${(props) => (props.isDesktop ? '3rem' : '1rem')};
  }

  h3 {
    font-size: ${(props) => (props.isDesktop ? '28px' : '22px')};
    margin: 0.75rem 0;
  }

  a {
    color: #990000;
    text-decoration: underline;
  }

  p {
    line-height: 1.5rem;
  }
`

export const FRows: FC<Fellows.Faculty> = ({ name, title, image, bio }) => {
  return (
    <Row style={{ marginBottom: '5rem' }} align="center">
      <Col xs={12} sm={12} lg={6}>
        <img
          src={image}
          alt={`${name} portrait`}
          height={'350px'}
          width={'350px'}
          style={{ objectFit: 'cover' }}
        />
      </Col>
      <Col xs={12} sm={12} lg={6} style={{ textAlign: 'left' }}>
        <h3>{name}</h3>
        <h4 style={{ margin: '0' }}>{title}</h4>
        <ReactMarkdown>{bio}</ReactMarkdown>
      </Col>
    </Row>
  )
}

const FacultyRows: FC<Prop> = ({ data }) => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)
  return (
    <Section isDesktop={isDesktop}>
      <Container style={{ maxWidth: '1440px' }}>
        {data.faculty.map((item) => (
          <FRows
            name={item.name}
            title={item.title}
            image={item.image}
            key={item.id}
            id={item.id}
            bio={item.bio}
          />
        ))}
      </Container>
    </Section>
  )
}

export default FacultyRows
