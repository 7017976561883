import React, { FC, useState, useRef, useEffect } from 'react'
import Logo from '../../assets/main_logo.jpg'
import mobileLogo from '../../assets/StonyLogo.png'
import { Row, useScreenClass, Hidden } from 'react-grid-system'
import styled from 'styled-components'
import { Button, Hamburger, Menu } from '.'
import { useOnClickOutside } from './Hamburger'
import { NavLink, Link } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'

const LinkWrapper = styled.div`
  .nav-link {
    color: ${(props) => props.theme.colors.black};
    font-size: 18px;
    margin: 0 12px;
  }

  a:last-child {
    margin-left: 12px;
  }
`

const Nav = styled.nav<{ scrollY: number; isDesktop: boolean }>`
  padding: 8px 3rem;
  background-color: white;
  transition: position 0.8s;
  position: ${(props) =>
    props.isDesktop && props.scrollY > 83 ? 'fixed' : ''};
  top: ${(props) => (props.isDesktop && props.scrollY > 83 ? '0' : '')};
  left: ${(props) => (props.isDesktop && props.scrollY > 83 ? '0' : '')};
  z-index: ${(props) => (props.isDesktop && props.scrollY > 83 ? '99' : '')};
  width: ${(props) =>
    props.isDesktop && props.scrollY > 83 ? 'calc(100% - 6rem)' : ''};
`

const activeStyles = {
  color: '#990000',
  fontWeight: 700,
  letterSpacing: '1px',
  transition: 'font-weight 0.3s',
}

const NavBar: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)
  const [openMenu, setOpenMenu] = useState(false)
  const [yPos, setYPos] = useState(0)
  const burger = useRef(null)
  useOnClickOutside(burger, () => setOpenMenu(false))

  useEffect(() => {
    window.addEventListener('scroll', () => setYPos(window.scrollY))
  })

  return (
    <Nav scrollY={yPos} isDesktop={isDesktop}>
      <Row justify="between" align="center">
        <Link to="/">
          {['lg', 'xl'].includes(screenClass) ? (
            <img
              src={Logo}
              alt="Stony Brook University Logo"
              style={{ width: '80%' }}
            />
          ) : (
            <img
              src={mobileLogo}
              alt="Stony Brook University Logo"
              style={{ width: '90px' }}
            />
          )}
        </Link>
        <Hidden lg xl>
          <div ref={burger}>
            <Hamburger open={openMenu} setOpen={setOpenMenu} />
            <Menu open={openMenu} />
          </div>
        </Hidden>
        <Hidden xs sm md>
          <LinkWrapper>
            <a
              href="https://docs.google.com/document/d/e/2PACX-1vQ8PIc9oa9DRRNByVriyNoZSRuim7Ew1yr1SBzhQ-XZlBRv4kd4q-I1KNZTh44F97Xe6RnK-yH_-Af1/pub"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
            >
              Syllabus
            </a>
            <NavLink
              to="/shows"
              // activeStyle={activeStyles}
              className="nav-link"
            >
              Our Shows
            </NavLink>
            <NavLink
              to="/faculty"
              // activeStyle={activeStyles}
              className="nav-link"
            >
              Faculty
            </NavLink>
            <NavLink
              to="/resources"
              // activeStyle={activeStyles}
              className="nav-link"
            >
              Resources
            </NavLink>
            <NavLink
              to="/alumni"
              // activeStyle={activeStyles}
              className="nav-link"
            >
              Alumni
            </NavLink>
            <NavLink
              to="/guests"
              // activeStyle={activeStyles}
              className="nav-link"
            >
              Guests
            </NavLink>
            <NavHashLink
              to="/#contact"
              // activeStyle={activeStyles}
              className="nav-link"
            >
              
              Contact
            </NavHashLink>
            <Button variant="red" path="/apply">
              About Us
            </Button>
          </LinkWrapper>
        </Hidden>
      </Row>
    </Nav>
  )
}

export default NavBar
