import React, { CSSProperties, FC } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface ButtonProp {
  variant: 'red' | 'black'
  size?: 'sm' | 'lg'
  path: string
  type?: 'button' | 'submit' | 'reset'
  style?: CSSProperties
}

const IButton = styled.button<{ variant: string; size: string }>`
  padding: ${(props) =>
    props.size === 'lg' ? '1.25rem 3.25rem' : '14px 2.5rem'};
  border: none;
  background-color: ${(props) =>
    props.variant === 'red'
      ? props.theme.colors.red
      : props.theme.colors.black};
  color: white;
  cursor: pointer;
  font-size: ${(props) => (props.size === 'lg' ? '22px' : '16px')};
  font-weight: 600;
  letter-spacing: 1.75px;
  transition: backgound-color 0.3s;

  a {
    color: white;
    text-decoration: none;
  }

  &:hover {
    background-color: ${(props) =>
      props.variant === 'red' ? '#B80000' : '#393939'};
  }
`

const Button: FC<ButtonProp> = ({
  variant,
  children,
  size = 'sm',
  path,
  type,
  style,
}) => {
  return (
    <Link to={path}>
      <IButton variant={variant} size={size} type={type} style={style}>
        {children}
      </IButton>
    </Link>
  )
}

export default Button
